import React from 'react';
import {IconButton} from '@mui/material';
import {Edit} from '@mui/icons-material';
import styled from 'styled-components';
import {InitScorerFormData} from 'app/components/match/liveResult/updateScorer/sharedTypes';
import {useSoftTickerContext} from 'app/hooks/useSoftTickerContext';
import {openUpdateScorer} from 'app/actions/softTickerActions';

const Wrapper = styled.div`
  align-self: flex-start;
`;

interface EditScorerButtonProps {
  initData: InitScorerFormData;
  matchEventId: number;
}

export default function EditScorerButton({initData, matchEventId}: EditScorerButtonProps) {
  const {dispatch} = useSoftTickerContext();
  const handleClick = () => dispatch(openUpdateScorer({scorerData: initData, id: matchEventId}));

  return (
    <Wrapper>
      <IconButton size='small' onClick={handleClick}>
        <Edit color='action' />
      </IconButton>
    </Wrapper>
  );
}
