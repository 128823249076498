import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';

const EventInformation = styled(FlexContainer)<{customBgColor?: string}>`
  align-self: start;
  flex-direction: column;
  align-items: center;
  min-width: 3rem;
  background: ${props => (props.customBgColor ? props.customBgColor : COLORS.lightGrey)};
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  padding: 0 0.5rem;
`;

const Minute = styled(Typography)<{customBgColor?: string}>`
  line-height: ${SIZES['24']};
  &&& {
    color: ${props => (props.customBgColor ? COLORS.white : COLORS.darkestGrey)};
  }
`;

const MatchEventMinute = ({
  minute,
  customBgColor,
  className,
}: {
  minute: string;
  customBgColor?: string;
  className?: string;
}) => {
  const label = minute === `0'` ? 'vor Spielbeginn' : minute;

  return (
    <EventInformation className={className} customBgColor={customBgColor}>
      <Minute customBgColor={customBgColor} variant='button-sub' component='span'>
        {label}
      </Minute>
    </EventInformation>
  );
};

export default MatchEventMinute;
